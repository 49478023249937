<template>
  <v-container justify="center">
    <v-row xs="12" xl="8">
      <v-col>
        <!-- notification area -->
        <div class="sticky-row">
          <v-alert
            v-show="error"
            class="custom-alert"
            transition="fade-transition"
            type="error"
            dense
          >
            <v-row align="center">
              <v-col class="grow">
                {{ errorMessage }}
              </v-col>
              <v-col class="shrink">
                <v-btn title="close" icon>
                  <v-icon @click="error = false"> mdi-close </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </div>

        <v-row v-if="loading">
          <v-col>
            <h3>
              Active Mortgages Available
              <v-progress-circular indeterminate color="primary" />
            </h3>
          </v-col>
        </v-row>

        <v-row v-if="!loading" justify="center">
          <v-col xs="12" xl="8">
            <v-row>
              <v-col>
                <div class="image-container">
                  <v-img
                    src="@/assets/active_loans_available.png"
                    :aspect-ratio="aspectRatio"
                    class="darkened-image"
                  ></v-img>

                  <div class="centered-text">
                    <h1 :class="headerText">Lending Opportunities</h1>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h3
                  :class="windowWidth >= 600 ? 'text-h4 mb-2' : 'text-h5 mb-2'"
                >
                  Active Mortgages Available
                </h3>

                <p
                  :class="
                    windowWidth >= 600
                      ? 'grey--text text-subtitle-1'
                      : 'grey--text text-subtitle-2'
                  "
                >
                  Below is a list of mortgages which are currently available for
                  offers. Click a mortgage to see more details and make an
                  offer.
                </p>

                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                />

                <v-data-table
                  v-if="windowWidth >= 960"
                  @click:row="handleClick"
                  :headers="headers"
                  :items="filteredDealList"
                  :items-per-page="-1"
                  class="mt-5"
                  dense
                >
                  <template v-slot:item.city="{ item }">
                    <span style="text-transform: capitalize">{{
                      item.city
                    }}</span>
                  </template>
                  <template v-slot:item.property_type="{ item }">
                    <span style="text-transform: capitalize">{{
                      item.property_type
                    }}</span>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <span style="text-transform: capitalize">{{
                      item.status
                    }}</span>
                  </template>

                  <template v-slot:item.amount_requested="{ item }">
                    {{ item.amount_requested | formatPrice }}
                  </template>

                  <template v-slot:item.loan_to_value="{ item }">
                    {{ item.loan_to_value }}%
                  </template>
                </v-data-table>

                <v-card
                  v-for="item in windowWidth < 960 ? filteredDealList : []"
                  :key="item.id"
                  class="my-5 bordered rounded-lg"
                >
                  <v-card-title>
                    <v-spacer />
                    <!-- add menu for more actions -->
                  </v-card-title>

                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr class="border-bottom">
                            <td :width="windowWidth > 600 ? '200' : '150'">
                              City
                            </td>
                            <td>{{ item.city | capitalize_first_letter }}</td>
                          </tr>

                          <tr class="border-bottom">
                            <td>Province</td>
                            <td>{{ item.province }}</td>
                          </tr>

                          <tr class="border-bottom">
                            <td>Property Type</td>
                            <td>{{ item.property_type }}</td>
                          </tr>

                          <tr class="border-bottom">
                            <td>LTV</td>
                            <td>{{ item.loan_to_value }}%</td>
                          </tr>

                          <tr class="border-bottom">
                            <td>Requested</td>
                            <td>{{ item.amount_requested | formatPrice }}</td>
                          </tr>

                          <tr class="border-bottom">
                            <td>Status</td>
                            <td>{{ item.status }}</td>
                          </tr>

                          <tr class="border-bottom">
                            <td>Name</td>
                            <td>{{ item.deal.public_name }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="teal" text @click="handleClick(item)">
                      <v-icon class="mr-2"
                        >mdi-information-variant-circle-outline</v-icon
                      >
                      view info
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";

export default {
  data() {
    return {
      loading: true,
      error: false,
      errorMessage: "",

      search: "",

      windowWidth: window.innerWidth,

      dealList: [],

      headers: [
        {
          text: "City",
          align: "start",
          filterable: true,
          value: "city",
        },
        {
          text: "Province",
          align: "start",
          filterable: true,
          value: "province",
        },
        {
          text: "Type",
          align: "start",
          filterable: true,
          value: "property_type",
        },

        {
          text: "LTV",
          value: "loan_to_value",
        },
        {
          text: "Requested",
          value: "amount_requested",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Name",
          value: "deal.public_name",
          width: "150px",
        },
      ],
    };
  },

  computed: {
    aspectRatio() {
      if (this.windowWidth >= 1264) {
        return 3;
      } else if (this.windowWidth >= 960) {
        return 2.5;
      } else if (this.windowWidth >= 600) {
        return 2;
      } else {
        return 1.5;
      }
    },

    headerText() {
      if (this.windowWidth >= 960) {
        return "text-center text-h3 font-weight-black";
      } else {
        return "text-center text-h4 font-weight-black";
      }
    },

    filteredDealList() {
      if (this.search) {
        return this.dealList.filter((deal) => {
          // Adjust this line to match the properties you want to search
          return (
            deal.amount_requested.toString().includes(this.search) ||
            deal.loan_to_value.toString().includes(this.search) ||
            deal.status.toLowerCase().includes(this.search.toLowerCase()) ||
            deal.city.toLowerCase().includes(this.search.toLowerCase()) ||
            deal.province.toLowerCase().includes(this.search.toLowerCase()) ||
            deal.property_type
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            deal.deal.public_name
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        });
      } else {
        return this.dealList;
      }
    },
  },

  methods: {
    handleClick(value) {
      this.$router.push(`/dashboard/mortgagesAvailable/read/${value.id}`);
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    async getData() {
      try {
        window.addEventListener("resize", this.handleResize);

        if (this.$route.query.status == "all") {
          this.listType = "- All";
        } else if (this.$route.query.status == "active") {
          this.listType = "- Active";
        } else if (this.$route.query.status == "awarded") {
          this.listType = "- Awarded";
        } else if (this.$route.query.status == "funded") {
          this.listType = "- Funded";
        } else if (this.$route.query.status == "inactive") {
          this.listType = "- Inactive";
        }

        const res = await API().get(
          // `api/deal-summaries/?filters[status][$eq]=active`
          // `api/deal-summaries/?populate[0]=deal&filters[status][$eq]=active`
          "/api/deal-summary/user_deal_summaries_available_list"
        );

        if (res.status == 200) {
          
          this.dealList = res.data;
          this.loading = false;
        } else {
          this.error = true;
          this.errorMessage = "Error message: unable to fetch data";
          this.loading = false;
        }
      } catch (error) {
        console.log("Error message: ", error.response.data.message);
        this.error = true;
        this.errorMessage = "Error message: " + error.response.data.message;
        this.loading = false;
      }
    },
  },

  filters: {
    ...filt,
  },

  mounted() {
    this.getData();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
.image-container {
  position: relative;
}

.darkened-image {
  filter: brightness(50%);
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
  color: #ff6633;
  font-size: 1.5em;
  font-weight: bold;
}

.custom-alert .v-icon {
  align-self: center;
}

.sticky-row {
  position: sticky;
  top: 64px;
  z-index: 2;
}

.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
